<template>
  <div class="staff">
    <div class="left-top">
      <span style="font-size: 18px; font-weight: 400; color: #333"
        >视频管理</span
      >
      <el-divider></el-divider>
      <el-button
        type="primary"
        @click="addPage"
        style="float: left; margin: 10px 5px; padding: 15px 20px"
      >
        <i class="el-icon-circle-plus-outline" style="margin-right: 5px"></i
        >添加视频
      </el-button>
      <el-form
        :model="searchForm"
        :inline="true"
        ref="searchForm"
        class="searchForm"
      >
        <el-form-item label="视频名称" prop="Title">
          <el-input
            style="width: 200px"
            v-model="searchForm.Title"
            placeholder="输入视频名称搜索"
          ></el-input>
        </el-form-item>
        <el-form-item label="视频类型:" prop="HelpCenterCategoryId">
          <el-select
            style="width: 140px"
            filterable
            v-model="parentValue"
            @change="selParent"
            placeholder="全部"
          >
            <el-option
              v-for="item in Alltype"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="keysSearch(1)">
            <i class="el-icon-search" style="margin-right: 5px"></i>
            搜索
          </el-button>
          <el-button @click="resetForm()">
            <i class="el-icon-refresh-right" style="margin-right: 5px"></i>
            重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="PageConfigure" row-key="index" style="width: 100%">
      <el-table-column
        fixed
        label="序号"
        width="80"
        type="index"
        align="center"
      ></el-table-column>
      <el-table-column label="视频名称" prop="Title" align="center">
      </el-table-column>
      <el-table-column
        label="视频类型"
        prop="HelpCenterCategoryName"
        align="center"
      ></el-table-column>
      <el-table-column label="视频" min-width="300" align="center">
        <template slot-scope="scope">
          <video
            :src="scope.row.Content"
            controls="controls"
            class="video-box"
            style="width: 300px; height: 200px"
          ></video>
        </template>
      </el-table-column>
      <el-table-column
        sortable
        label="排序"
        width="100"
        prop="SortNumber"
        align="center"
      >
        <template slot-scope="scope">
          <span style="font-weight: 700">{{ scope.row.SortNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column label="是否显示" width="120" align="center">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.IsShow"
            :active-value="true"
            :inactive-value="false"
            @change="changeStatus(scope.row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column fixed="right" width="200" label="操作" align="center">
        <template slot-scope="scope">
          <span
            class="backSpan"
            @click="eduit(scope.row)"
            style="color: #409eff"
            >编辑</span
          >
          <span
            class="backSpan"
            @click="PreDelet(scope.row)"
            style="color: #cc0000"
            >删除</span
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="task-list-page" v-if="PageConfigure !== ''">
      <el-pagination
        @current-change="OnCurrentPageChanged"
        @size-change="handleSizeChange"
        :current-page="Params.PageIndex"
        :page-sizes="[10, 20, 30, 40, 50, 60]"
        layout="prev, pager, next,sizes,jumper,total"
        :page-size="Params.PageSize"
        background
        :total="Params.TotalCount"
      >
      </el-pagination>
    </div>
    <!-- 新增/编辑 -->
    <div class="formdia">
      <el-dialog
        :modal-append-to-body="false"
        :visible.sync="dialogFormVisible"
        custom-class="edit-form"
        :title="dialongtitle"
      >
        <el-form :model="Rowcontent" :rules="rules" ref="editForm">
          <el-form-item
            label="视频名称:"
            :label-width="formLabelWidth"
            prop="Title"
          >
            <el-input
              style="width: 340px"
              v-model="Rowcontent.Title"
              placeholder="请输入视频名称"
              maxlength="30"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="视频类型"
            :label-width="formLabelWidth"
            prop="HelpCenterCategoryId"
          >
            <el-select
              style="width: 340px"
              v-model="typeValue"
              @change="seltype"
              filterable
              placeholder="请选择视频类型"
            >
              <el-option
                v-for="item in Alltype"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="排序:"
            type="number"
            :label-width="formLabelWidth"
            prop="sortNumber"
          >
             
            <el-input
              style="width: 340px"
              v-model="Rowcontent.sortNumber"
              placeholder="请输入数字，数字越大排序越靠前"
              maxlength="40"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="上传视频"
            :label-width="formLabelWidth"
            prop="Content"
          >
            <video
              controls
              prop="Content"
              :style="
                videoshow == 1
                  ? 'width:300px;height:200px;'
                  : 'width:200px;height:100px;'
              "
              :src="videoshow == 1 ? Rowcontent.Content : ''"
            ></video>
            <el-upload
              :show-file-list="false"
              :action="video_Url"
              :headers="headers"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <el-button style="width: 120px" type="primary">
                上传视频<i class="el-icon-upload el-icon--right"></i>
              </el-button>
            </el-upload>
            <p
              style="
                color: #f56c6c;
                font-size: 12px;
                line-height: 20px;
                margin-top: 5px;
              "
            >
              仅mp4格式，视频大小不超过100M
            </p>
          </el-form-item>
          <el-form-item
            label="是否显示:"
            type="boolean"
            :label-width="formLabelWidth"
            prop="IsShow"
          >
            <el-switch
              v-model="Rowcontent.IsShow"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
            <span
              style="color: #13ce66; padding-left: 8px"
              v-show="Rowcontent.IsShow"
            ></span>
            <span
              style="color: #ff4949; padding-left: 8px"
              v-show="!Rowcontent.IsShow"
            ></span>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">返回</el-button>
          <el-button :disabled="isDisabled" @click="submitForm('editForm')">
            确定
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    var numberRules = (rule, value, callback) => {
      if (/^[0-9]\d*$/.test(value)) {
        callback();
      } else {
        callback(new Error("请输入整数"));
      }
    };
    return {
      dialongtitle: "",
      dialogFormVisible: false,
      formLabelWidth: "120px",
      Rowcontent: {
        HelpCenterQuestionId: 0, //视频id
        Title: "", //视频名称
        Content: "", //视频url
        HelpCenterCategoryId: "", //视频类型id
        HelpCenterCategoryName: "", //视频类型名称
        IsShow: false, //是否显示
        sortNumber: "", //排序
      },
      VideoSumbit: {
        VideoName: "",
        CategoryId: 0,
        VideoUrl: "",
        IsShow: false,
        SortNumber: "",
      },
      rules: {
        Title: [{ required: true, message: "请完善信息", trigger: "blur" }],
        Content: [{ required: true, message: "请完善信息", trigger: "blur" }],
        HelpCenterCategoryId: [
          { required: true, message: "请完善信息", trigger: "blur" },
        ],
        sortNumber: [
          { required: true, message: "请完善信息", trigger: "blur" },
          { validator: numberRules, trigger: "blur" },
        ],
        IsShow: [{ required: true, message: "请完善信息", trigger: "blur" }],
      },
      PageConfigure: [], //列表分页数据
      Params: {
        PageIndex: 1,
        PageSize: 10,
        TotalCount: 5,
        TotalPage: 0,
      },
      IsEdit: false, //判断新增还是编辑
      Alltype: [], //获取所有分类
      //查询数据
      searchForm: {
        Title: "",
      },
      parentValue: "",
      typeValue: "",
      searchContent: {},
      video_Url:"https://busapi.1renshi.com/Common/UploadFile/V2?module=email&fileExpand=.mp4",
      headers:{},
      videoshow:'1',
      //解决重复点击问题
      isDisabled: false,
    };
  },
  created() {
    this.GetCookie();
    this.searchDate();
    this.getalltype();
  },
  methods: {
    //刷新
    refresh() {
      this.$router.go(0);
    },
    GetCookie(){
      var cookies=document.cookie.split("; ");
      for(var i=0;i<cookies.length;i++){
        var arr=cookies[i].split("=");
          if('tokenAdmin'==arr[0]){
            this.headers={
              'Authorization':'Bearer '+arr[1],
            }
          }
      }
    },
    //获取数据
    searchDate() {
      var _this = this;
      _this.Params.CategoryId = -1;
      this.$get(_this.$api.GetAdminVideoList, { ..._this.Params })
        .then((res) => {
          _this.PageConfigure = res.Data;
          console.log(this.PageConfigure);
          _this.Params.TotalCount = res.TotalNumber;
          _this.Params.TotalPage = res.TotalPage;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //获取所属分类
    getalltype() {
      var _this = this;
      this.$get(this.$api.GetAllVideoType).then((res) => {
        for (let i = 0; i < res.length; i++) {
          let a = res[i].HelpCenterCategoryName;
          let b = res[i].HelpCenterCategoryId;
          _this.Alltype.push({ label: a, value: a, id: b });
        }
      });
    },

    //是否开启
    changeStatus(row, index, data) {
      let enable;
      console.log(row.IsShow);
      row.IsShow == 1 ? (enable = true) : (enable = false);
      this.$post(
        this.$api.HelpCenterOperate +
          "?questionId=" +
          row.HelpCenterQuestionId +
          "&operationType=2"
      ).then((res) => {
        if (res.IsSuccess) {
          this.$message({
            message: "修改成功",
            type: "success",
            duration: "800",
          });
          //this.searchDate();
        }
      });
    },

    //新增
    addPage() {
      this.IsEdit = false;
      this.dialongtitle = "添加视频";
      this.dialogFormVisible = true;
      this.Rowcontent.HelpCenterQuestionId = 0;
      this.Rowcontent.Title = "";
      this.Rowcontent.Content = "";
      this.Rowcontent.IsShow = false;
      this.Rowcontent.sortNumber = "";
      this.typeValue = "";
      this.videoshow = "2";
    },
    //编辑
    eduit(val) {
      var _this = this;
      this.IsEdit = true;
      this.dialongtitle = "编辑视频";
      this.dialogFormVisible = true;
      this.videoshow = "1";
      this.Rowcontent.HelpCenterQuestionId = val.HelpCenterQuestionId;
      this.Rowcontent.Title = val.Title;
      this.Rowcontent.Content = val.Content;
      this.Rowcontent.IsShow = val.IsShow;
      this.Rowcontent.sortNumber = val.SortNumber;
      this.Rowcontent.HelpCenterCategoryId = val.HelpCenterCategoryId;
      this.Alltype.forEach(function (item, index) {
        if (val.HelpCenterCategoryId == item.id) {
          _this.typeValue = item.value;
        }
        if (val.HelpCenterCategoryId == 0) {
          _this.typeValue = "";
        }
      });
    },

    //新增、编辑的提交
    entime() {
      setTimeout(() => {
        this.isDisabled = false;
      }, 500);
    },
    submitForm(editForm) {
      var _this = this;
      this.isDisabled = true;
      this.entime();
      this.VideoSumbit.VideoId = this.Rowcontent.HelpCenterQuestionId;
      this.VideoSumbit.VideoName = this.Rowcontent.Title;
      this.VideoSumbit.CategoryId = this.Rowcontent.HelpCenterCategoryId;
      this.VideoSumbit.VideoUrl = this.Rowcontent.Content;
      this.VideoSumbit.IsShow = this.Rowcontent.IsShow;
      this.VideoSumbit.SortNumber = this.Rowcontent.sortNumber;
      this.$refs[editForm].validate((valid) => {
        if (valid) {
          if (this.IsEdit) {
            console.log("编辑");
            console.log(this.VideoSumbit);
            this.$post(this.$api.EditVideo, null, this.VideoSumbit)
              .then((res) => {
                if (res.IsSuccess) {
                  _this.$message({
                    message: "编辑成功",
                    type: "success",
                    duration: "1500",
                  });
                  _this.searchDate();
                }
              })
              .catch((err) => {
                console.log(err);
              });
            this.dialogFormVisible = false;
          } else {
            console.log("新增");
            console.log(this.VideoSumbit);
            this.$post(this.$api.AddVideo, null, this.VideoSumbit)
              .then((res) => {
                if (res.IsSuccess) {
                  _this.$message({
                    message: "新增成功",
                    type: "success",
                    duration: "1500",
                  });
                  _this.searchDate();
                }
              })
              .catch((err) => {
                console.log(err);
              });
            this.dialogFormVisible = false;
          }
        } else {
          this.$message({
            message: "请完善信息",
            type: "warning",
            duration: "1500",
          });
        }
      });
    },

    //获取分页
    OnCurrentPageChanged(val) {
      this.Params.PageIndex = val;
      console.log(this.Params.PageIndex);
      this.keysSearch();
    },
    handleSizeChange(val) {
      this.Params.PageSize = val;
      console.log(val);
      this.keysSearch();
    },
    //视频上传
    handleAvatarSuccess(res, file) {
      this.videoshow = "1";
      console.log(res);
      this.Rowcontent.Content = res.data;
    },
    beforeAvatarUpload() {
      console.log("ssss");
      console.log(this.Rowcontent);
      console.log(this.Rowcontent.Content);
    },
    //新增/编辑 上级分类
    seltype() {
      var _this = this;
      this.Alltype.forEach(function (item, index) {
        if (item.value == _this.typeValue) {
          _this.Rowcontent.HelpCenterCategoryId = item.id;
          _this.Rowcontent.HelpCenterCategoryName = item.value;
        }
      });
    },
    //搜索 上级分类
    selParent() {
      var _this = this;
      this.Alltype.forEach(function (item, index) {
        if (item.value == _this.parentValue) {
          _this.searchForm.HelpCenterCategoryId = item.id;
        }
      });
    },
    //查询
    keysSearch(num) {
      var _this = this;

      if (
        _this.searchForm.Title != null &&
        _this.searchForm.Title.trim() != ""
      ) {
        _this.searchContent.keyWords = this.searchForm.Title;
      }
      if (
        _this.searchForm.HelpCenterCategoryId != null &&
        _this.searchForm.HelpCenterCategoryId != ""
      ) {
        _this.searchContent.categoryId = this.searchForm.HelpCenterCategoryId;
      }     
      if (num == 1) {
        this.Params.PageIndex = 1;
      }
      // console.log(_this.searchContent)
      this.$get(this.$api.GetAdminVideoList, {
        ...this.searchContent,
        ...this.Params,
      }).then((res) => {
        _this.PageConfigure = res.Data;
        _this.searchContent = {};
        _this.Params.TotalCount = res.TotalNumber;
        _this.Params.TotalPage = res.TotalPage;
      });
    },
    //删除
    PreDelet(row, index, data) {
      this.$confirm("确认删除？", "", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        iconClass: "el-icon-question",
      })
        .then(() => {
          let VideoId = row.HelpCenterQuestionId;
          this.$post(this.$api.HelpCenterOperate + `?questionId=${VideoId}&operationType=1`)
            .then((res) => {
              if (res.IsSuccess) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.searchDate();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //重置查询
    resetForm() {
      this.searchForm.Title = "";
      this.searchForm.HelpCenterCategoryId = "";
      this.parentValue = "";
      this.searchDate();
    },
  },
};
</script>

<style lang="stylus" scoped>
/deep/.is-center.is-leaf {
  background: #e6e6e6;
  color: #303133;
}

/deep/.has-gutter tr {
  th {
    background: #e6e6e6;
    color: #303133;
  }

  td {
    text-align: center;
  }
}

.staff {
  background: #fff;
  padding: 10px 10px;
  border-radius: 10px;

  .searchForm {
    margin-left: 150px;

    .el-form-item {
      vertical-align: middle;
      margin-top: 13px;
      margin-right: 30px;
    }
  }

  .backSpan {
    padding: 0px 12px;
    cursor: pointer;
  }

  .left-top {
    margin-bottom: 10px;

    .el-divider--horizontal {
      margin: 10px 0;
    }

    button {
      margin-left: 10px;
    }
  }

  /deep/.el-table .cell {
    white-space: nowrap; /* 不换行 */
  }

  .el-table .cell span {
    overflow: hidden; /* 超出部分隐藏 */
    white-space: nowrap; /* 不换行 */
    text-overflow: ellipsis; /* 超出部分文字以...显示 */
  }

  .task-list-page {
    margin-top: 10px;
  }
}

/* 编辑框样式 */
/deep/.edit-form {
  width: 520px;

  .el-dialog__header {
    height: 50px;
    text-align: center;
    background-color: #409eff;

    .el-dialog__title {
      line-height: 0px;
      font-size: 22px;
      color: #fff;
    }

    .el-dialog__headerbtn {
      display: none;
    }
  }

  .el-form-item .el-textarea__inner {
    resize: none;
  }

  .el-dialog__body {
    padding-bottom: 5px !important;
  }

  .dialog-footer {
    text-align: center;

    .el-button {
      background-color: #fff;
      color: #606266;
      border: 1px solid #dcdfe6;
    }

    .el-button:hover {
      background-color: #409eff;
      border-color: #409eff;
      color: #fff;
    }
  }

  img.upImg {
    width: 40px;
    height: 40px;
    background-size: 100% 100%;
    float: left;
  }
}

// 小星星
.xiaoxx {
  color: #F56C6C;
  position: absolute;
  top: 0;
  left: -10px;
}
</style>